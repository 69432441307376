<template>
<div>
    <v-btn icon @click="setTheme()">
        <v-icon>{{ $vuetify.theme.isDark == true ? 'light_mode' : 'dark_mode' }}</v-icon>
    </v-btn>
</div>
</template>

<script>
export default {
    mounted() {
        const theme = sessionStorage.getItem("dark_theme");
        if (theme) {
            if (theme == "true") {
                this.$vuetify.theme.dark = true;
            } else {
                this.$vuetify.theme.dark = false;
            }
        }
    },
    methods: {
        setTheme() {
            this.$vuetify.theme.dark = !this.$vuetify.theme.dark
            sessionStorage.setItem("dark_theme", this.$vuetify.theme.dark.toString());
        },
    }
}
</script>
